<template>
  <v-container
    tag="section"
    fluid
  >
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <v-form @submit.prevent="upload">
          <v-card>
            <v-card-title>
              Importer un fichier de produits externes
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                  sm="12"
                >
                  <v-file-input
                    v-model="file"
                    :prepend-icon="null"
                    prepend-inner-icon="mdi-file-delimited-outline"
                    accept="text/csv"
                    placeholder="Fichier csv"
                    label="Fichier csv"
                    outlined
                    hide-details
                    dense
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-col
                md="6"
                align="right"
              >
                <v-btn
                  type="submit"
                  color="primary"
                >
                  {{ $t('forms.buttons.upload') }}
                </v-btn>
              </v-col>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
    <Snackbar />
  </v-container>
</template>

<script>
import Snackbar from '@/components/core/Snackbar.vue'
import snackbarMixin from '@/mixins/snackbar'

export default {
  name: 'SynchroLinesUploadImparfaite',
  components: { Snackbar },
  mixins: [ snackbarMixin ],
  data () {
    return {
      file: null,
      loading: false,
    }
  },
  methods: {
    async upload() {
      if (this.file === null) {
        return
      }
      this.loading = true

      const formData = new FormData()
      formData.append('file', this.file)

      try {
        const response = await this.$axios.post(
          'external_catalog/synchro_lines/upload_imparfaite',
          formData,
          { headers: { 'Content-Type': 'multipart/form-data' } }
        )

        this.snackbarSuccess(
          this.$i18n.t('views.synchro_lines.forms.message.upload.success', { ...response.data })
        )
        this.file = null
      } catch (error) {
        this.snackbarError(this.$i18n.t('views.synchro_lines.forms.message.upload.failed', { error: 'internal error' }))
      } finally {
        this.file = null
      }
    },
  },
}
</script>
